<template>
  <div class="auth-area__form">

    <h3 class="form-title">Регистрация в системе</h3>

    <form action="#" class="form-auth-login" @submit.prevent="register">
      <span v-if="serverErrors"
            class="reg-danger reg-danger_auth"
            style="padding-bottom: 10px"
      >
        {{ showServerError("recaptchaToken") }}
      </span>
      <div class="auth-field">
        <p>
          <label for="reg-email" class="auth_label">E-mail</label>
        </p>
        <input v-model.trim="regData.USER_EMAIL"
               type="text"
               name="reg-email"
               class="auth_input"
               :class="{invalid: ($v.regData.USER_EMAIL.$dirty && !$v.regData.USER_EMAIL.required) || ($v.regData.USER_EMAIL.$dirty && !$v.regData.USER_EMAIL.email)}"
        />
        <span v-if="$v.regData.USER_EMAIL.$dirty && !$v.regData.USER_EMAIL.required"
              class="reg-danger reg-danger_auth"
        >
          введите email
        </span>
        <span v-else-if="$v.regData.USER_EMAIL.$dirty && !$v.regData.USER_EMAIL.email"
              class="reg-danger reg-danger_auth"
        >
          email введен неверно
        </span>
        <span v-if="errorMessage('USER_EMAIL')"
              class="reg-danger reg-danger_auth"
        >
          {{ showServerError("USER_EMAIL") }}
        </span>
      </div>
      <div class="auth-field">
        <p>
          <label for="reg-pass" class="auth_label">Пароль</label>
        </p>
        <input v-model.trim="regData.USER_PASSWORD"
               type="password"
               name="reg-pass"
               autocomplete="new-password"
               class="auth_input" value=""
               @keydown.space.prevent
               :class="{invalid: ($v.regData.USER_PASSWORD.$dirty && !$v.regData.USER_PASSWORD.required) || ($v.regData.USER_PASSWORD.$dirty && !$v.regData.USER_PASSWORD.minLength) || ($v.regData.USER_PASSWORD.$dirty && !$v.regData.USER_PASSWORD.valid)}"
        />
        <span v-if="$v.regData.USER_PASSWORD.$dirty && !$v.regData.USER_PASSWORD.required"
              class="reg-danger reg-danger_auth"
        >
          введите пароль
        </span>
        <span v-else-if="$v.regData.USER_PASSWORD.$dirty && !$v.regData.USER_PASSWORD.minLength"
              class="reg-danger reg-danger_auth"
        >
          длина пароля минимум 8 символов
        </span>
        <span v-else-if="$v.regData.USER_PASSWORD.$dirty && !$v.regData.USER_PASSWORD.valid"
              class="reg-danger reg-danger_auth"
        >
          пароль должен содержать латинские буквы, как минимум одну заглавную, а также одну цифру
        </span>
        <span v-if="serverErrors"
              class="reg-danger reg-danger_auth"
        >
          {{ showServerError("USER_PASSWORD") }}
        </span>
      </div>
      <div class="auth-field">
        <p>
          <label for="reg-pass-repeat" class="auth_label">Повторите пароль</label>
        </p>
        <input v-model.trim="regData.USER_CONFIRM_PASSWORD"
               type="password"
               name="reg-pass-repeat"
               class="auth_input" value=""
               @keydown.space.prevent
               :class="{invalid: ($v.regData.USER_CONFIRM_PASSWORD.$dirty && !$v.regData.USER_CONFIRM_PASSWORD.required) || ($v.regData.USER_CONFIRM_PASSWORD.$dirty && !$v.regData.USER_CONFIRM_PASSWORD.sameAsPassword)}"
        />
        <span v-if="$v.regData.USER_CONFIRM_PASSWORD.$dirty && !$v.regData.USER_CONFIRM_PASSWORD.required"
              class="reg-danger reg-danger_auth"
        >
          подтвердите пароль
        </span>
        <span v-else-if="$v.regData.USER_CONFIRM_PASSWORD.$dirty && !$v.regData.USER_CONFIRM_PASSWORD.sameAsPassword"
              class="reg-danger reg-danger_auth"
        >
          пароли не совпадают
        </span>
        <span v-if="serverErrors"
              class="reg-danger reg-danger_auth"
        >
          {{ showServerError("USER_CONFIRM_PASSWORD") }}
        </span>
      </div>
      <div class="auth-field">
        <p>
          <label for="fio" class="auth_label">ФИО</label>
        </p>
        <input v-model.trim="regData.USER_FIO"
               type="text"
               name="fio"
               class="auth_input"
               :class="{invalid: ($v.regData.USER_FIO.$dirty && !$v.regData.USER_FIO.required)}"
        />
        <span v-if="$v.regData.USER_FIO.$dirty && !$v.regData.USER_FIO.required"
              class="reg-danger reg-danger_auth">
          введите ФИО
        </span>
        <span v-if="serverErrors"
              class="reg-danger reg-danger_auth"
        >
          {{ showServerError("USER_FIO") }}
        </span>
      </div>
      <div class="auth-field">
        <p>
          <label for="mob-tell" class="auth_label">Мобильный телефон</label>
        </p>
        <input v-model="regData.USER_PHONE"
               type="tel"
               name="mob-tell"
               id="phone_number_mask"
               class="auth_input"
               @blur="onBlurPhone($event)"
               :class="{invalid: ($v.regData.USER_PHONE.$dirty && !$v.regData.USER_PHONE.required) || ($v.regData.USER_PASSWORD.$dirty && !$v.regData.USER_PASSWORD.validPhone)}"
        />
        <span v-if="$v.regData.USER_PHONE.$dirty && !$v.regData.USER_PHONE.required"
              class="reg-danger reg-danger_auth"
        >
          введите номер телефона
        </span>
        <span v-else-if="$v.regData.USER_PHONE.$dirty && !$v.regData.USER_PHONE.validPhone"
              class="reg-danger reg-danger_auth"
        >
          Неверно введен номер телефона
        </span>
        <span v-if="serverErrors"
              class="reg-danger reg-danger_auth"
        >
          {{ showServerError("USER_PHONE") }}
        </span>
      </div>
      <div class="auth-field">
        <p>
          <label for="snails" class="auth_label">СНИЛС</label>
        </p>
        <input v-model="regData.USER_SNILS"
               type="text"
               name="snails"
               id="snails_mask"
               class="auth_input"
               @blur="onBlurSnils($event)"
               :class="{invalid: ($v.regData.USER_SNILS.$dirty && !$v.regData.USER_SNILS.required) || ($v.regData.USER_SNILS.$dirty && !$v.regData.USER_SNILS.validSnils)}"
        />
        <span v-if="$v.regData.USER_SNILS.$dirty && !$v.regData.USER_SNILS.required"
              class="reg-danger reg-danger_auth"
        >
          введите СНИЛС
        </span>
        <span v-else-if="$v.regData.USER_SNILS.$dirty && !$v.regData.USER_SNILS.validSnils"
              class="reg-danger reg-danger_auth"
        >
          Неверно введен СНИЛС
        </span>
        <span v-if="serverErrors"
              class="reg-danger reg-danger_auth"
        >
          {{ showServerError("USER_SNILS") }}
        </span>
      </div>
      <div class="auth-field auth-field__org">
        <p>
          <label for="ogrn-org" class="auth_label">ОГРН организации</label>
        </p>
        <input v-model="regData.ORG_OGRN"
               type="text"
               name="ogrn-ogrn-org"
               id="ogrn-org_mask"
               class="auth_input"
               @blur="toggleOgrnInfo($event)"
               :class="{invalid: ($v.regData.ORG_OGRN.$dirty && !$v.regData.ORG_OGRN.required) || ($v.regData.ORG_OGRN.$dirty && !$v.regData.ORG_OGRN.validOgrn)}"
        />
        <span v-if="$v.regData.ORG_OGRN.$dirty && !$v.regData.ORG_OGRN.required"
              class="reg-danger reg-danger_auth"
        >
          введите номер ОГРН
        </span>
        <span v-else-if="$v.regData.ORG_OGRN.$dirty && !$v.regData.ORG_OGRN.validOgrn"
              class="reg-danger reg-danger_auth"
        >
          Неверно введен ОГРН
        </span>
        <span v-if="serverErrors"
              class="reg-danger reg-danger_auth"
        >
          {{ showServerError("ORG_OGRN") }}
        </span>
        <span v-if="ogrnError"
              class="reg-danger reg-danger_auth"
        >
          {{ ogrnError }}
        </span>
      </div>
      <span v-show="ogrn.isFind"
            class="form-org-name"
      >
        {{ ogrn.name }}
      </span>
      <div class="auth-field auth-field_check check-flex">
        <input type="checkbox"
               class="main_check"
               :disabled="brokerCheckbox.disabled"
               v-model="checkBox.broker"
        />
        <span class="auth_label label_check broker-check">Брокер</span>
      </div>
      <span class="soglasie-text">Согласие на обработку персональных данных:</span>
      <div class="auth-field auth-field_check check-flex">
        <input v-model="checkBox.USER_IS_AGREE_RULES"
               type="checkbox"
               class="main_check"
               :class="{invalid: ($v.checkBox.USER_IS_AGREE_RULES.$dirty && !$v.checkBox.USER_IS_AGREE_RULES.sameAs)}"
        />
        <span
            class="auth_label label_check">Согласен(-на)
          <button
              @click.prevent="showConsent"
              type="button"
              class="button-consent"
          >
            Согласие на обработку
          </button>
        </span>
        <div v-if="loading">
          <div class="spinner">
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div v-else>
          <consent
              v-show="isConsentVisible"
              @close="closeConsent"
              @closeAndCheck="closeAndCheckConsent"
              :consentText="consent.text"
              :consentName="consent.name"
          />
        </div>
      </div>
      <span v-if="$v.checkBox.USER_IS_AGREE_RULES.$dirty && !$v.checkBox.USER_IS_AGREE_RULES.sameAs"
            class="reg-danger"
      >
        подтвердите согласие
      </span>
      <span v-if="serverErrors"
            class="reg-danger reg-danger_auth"
      >
        {{ showServerError("USER_IS_AGREE_RULES") }}
      </span>
      <span class="reg-danger reg-danger_block">Внимание! Все поля обязательны для заполнения.</span>
      <div v-if="isUseCaptcha"
           class="google-captha"
      >
        <vue-recaptcha
            ref="recaptcha"
            size="checkbox"
            class="g-recaptcha"
            :sitekey="siteKey"
            @verify="validate"
            @expired="onCaptchaExpired"
        />
      </div>
      <input
          type="submit"
          class="auth_btn"
          @keyup.enter="register"
          value="зарегистрироваться"
      />
    </form>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha"
import Inputmask from 'inputmask';
import {email, minLength, required, sameAs,} from "vuelidate/lib/validators"
import {mapGetters} from "vuex";
import ApiService, {UserService} from "@/common/api.service";
import {OAUTH} from "@/store/actions.type";
import {SITE_KEY} from "@/common/config";
import Consent from "../popup/Consent";


export default {
  name: 'Registration',
  components: {
    VueRecaptcha,
    Consent,
  },
  data() {
    return {
      regData: {
        USER_EMAIL: '',
        USER_PASSWORD: '',
        USER_CONFIRM_PASSWORD: '',
        USER_FIO: '',
        USER_PHONE: '',
        USER_SNILS: '',
        ORG_OGRN: '',
        USER_IS_BROKER: "0",
        USER_IS_AGREE_RULES: "0",
        recaptchaToken: null,
      },
      siteKey: SITE_KEY,
      isUseCaptcha: false,
      ogrn: {
        name: 'piece',
        isFind: 0,
        isBroker: 0,
        isFindDB: 0,
      },
      brokerCheckbox: {
        disabled: false,
      },
      consent: {
        name: '',
        text: '',
      },
      checkBox: {
        broker: false,
        USER_IS_AGREE_RULES: false,
      },
      isConsentVisible: false,
      loading: false,
      recaptchaChecked: false,
      ogrnError: '',
      serverErrors: [],
      otherErrors: '',
      tosterErrors: [],
    }
  },

  computed: {
    ...mapGetters(["isAuthenticated", "currentUser"]),
  },

  beforeCreate: function () {
    document.body.className = 'gradient';
    document.documentElement.className = 'gradient';
  },

  mounted() {
    ApiService.get3("services/getStatusUseReCaptcha/")
        .then(response => (this.isUseCaptcha = response.data.IS_USE_RECAPTCHA))
        .catch(error => this.otherErrors = error)
    // let emailMask = new Inputmask("email");
    // emailMask.mask(document.getElementById('email_mask'));
    let phoneMask = new Inputmask("+7(999) 999-99-99");
    phoneMask.mask(document.getElementById('phone_number_mask'));
    let snailsMask = new Inputmask("999-999-999 99");
    snailsMask.mask(document.getElementById('snails_mask'));
    let ogrnMask = new Inputmask("9999999999999[99]");
    ogrnMask.mask(document.getElementById('ogrn-org_mask'));
  },

  destroyed() {
    const body = document.getElementsByTagName('body')[0];
    const html = document.documentElement;
    body.classList.remove('gradient');
    html.classList.remove('gradient');
  },

  validations: {
    regData: {
      USER_EMAIL: {email, required},
      USER_PASSWORD: {
        required,
        minLength: minLength(8),
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          return containsUppercase && containsLowercase && containsNumber;
        }
      },
      USER_CONFIRM_PASSWORD: {required, sameAsPassword: sameAs('USER_PASSWORD')},
      USER_FIO: {required},
      USER_PHONE: {
        required,
        validPhone: function (value) {
          return /(\+7)\(\d{3}\)\s*\d{3}-\d{2}-\d{2}/.test(value);
        }
      },
      USER_SNILS: {
        required,
        validSnils: function (value) {
          return /\d{3}-\d{3}-\d{3}\s\d{2}/.test(value);
        }
      },
      ORG_OGRN: {
        required,
        validOgrn: function (value) {
          return /\d{13,15}/.test(value);
        }
      },
    },
    checkBox: {
      USER_IS_AGREE_RULES: {sameAs: sameAs(() => true)}
    }
  },

  methods: {
    errorMessage(inputName) {
      if (this.serverErrors) {
        return this.serverErrors.filter(el => el.FIELD === inputName).length === 0;
      }
    },

    validate(recaptchaToken) {
      this.regData.recaptchaToken = recaptchaToken;
      this.recaptchaChecked = true;
    },

    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
      this.recaptchaChecked = false;
    },

    closeConsent() {
      this.isConsentVisible = false;
    },

    closeAndCheckConsent() {
      this.checkBox.USER_IS_AGREE_RULES = true;
      this.isConsentVisible = false;
    },

    showServerError(inputName) {
      let arrayError = this.serverErrors
          .filter((item) => {
            return (item["FIELD"] === inputName)
          })
          .map((item) => {
            return item["TEXT"];
          });
      return arrayError[0];
    },

    onBlurSnils(e) {
      const snils = e.srcElement.value;
      if (snils !== this.regData.USER_SNILS) {
        this.regData.USER_SNILS = snils;
      }
    },

    onBlurPhone(e) {
      const phone = e.srcElement.value;
      if (phone !== this.regData.USER_PHONE) {
        this.regData.USER_PHONE = phone;
      }
    },

    showConsent() {
      this.loading = true;
      ApiService.get3('info/getServicesPage/soglasie_personal/')
          .then(response => {
            this.consent.name = response.data.NAME;
            this.consent.text = response.data.PREVIEW_TEXT;
          })
      this.loading = false;
      this.isConsentVisible = true;
    },

    toggleOgrnInfo(e) {
      const ogrn = e.srcElement.value;
      let ogrnId;
      if (Number(ogrn) === Number(this.regData.ORG_OGRN)) {
        ogrnId = this.regData.ORG_OGRN;
      } else {
        ogrnId = ogrn;
        this.regData.ORG_OGRN = ogrn;
      }
      ApiService.get3(`orgs/getOrgByOGRN/${ogrnId}/`)
          .then(response => {
            if (response.data.ERROR) {
              this.ogrnError = response.data.ERROR;
              this.ogrn.isFind = undefined;
              this.ogrn.name = undefined;
              this.ogrn.isBroker = undefined;
              this.ogrn.isFindDB = undefined;
              return;
            }
            this.ogrn.isFind = response.data.IS_FIND;
            this.ogrn.name = response.data.ORG_INFO.UF_NAME;
            this.ogrn.isBroker = response.data.UF_IS_BROKER;
            this.ogrn.isFindDB = response.data.ORG_INFO.IS_FIND_DB;
            if (this.ogrn.isBroker) {
              this.checkBox.broker = true;
              this.brokerCheckbox.disabled = true;
            } else if (!this.ogrn.isFindDB) {
              this.checkBox.broker = false;
              //this.brokerCheckbox.disabled = true;
              this.brokerCheckbox.disabled = false;
            } else {
              this.checkBox.broker = false;
              this.brokerCheckbox.disabled = false;
            }
            this.ogrnError = undefined;
          })
    },

    register() {
      if ((this.isUseCaptcha && !this.recaptchaChecked) || this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      this.serverErrors = '';
      this.regData.USER_IS_AGREE_RULES = '1';
      this.checkBox.broker === true ? this.regData.USER_IS_BROKER = '1' : this.regData.USER_IS_BROKER = '0';
      const body = {...this.regData};
      ApiService.post('registration/', body)
          .then(
              ({data}) => {
                if (data.error === true) {
                  // this.serverErrors = data.errorArr;
                  if (data.errorArr.length > 0) {
                    data.errorArr.forEach(function (err) {
                      this.tosterErrors[this.tosterErrors.length] = this.$toasted.show(
                          err.TEXT,
                          {
                            theme: "bubble",
                            position: "bottom-right",
                            duration: null,
                            action: [
                              {
                                text: "Закрыть",
                                onClick: (e, toastObject) => {
                                  toastObject.goAway(0);
                                }
                              },
                              {
                                text: "Закрыть все",
                                onClick: () => {
                                  this.tosterErrors.forEach(function (err) {
                                    err.goAway(0);
                                  });
                                }
                              }
                            ]
                          }
                      );
                    }, this);
                  }
                  return;
                }
                if (data.isAuth === 0) {
                  this.$router.push({name: 'Login'});
                }
                this.$store.dispatch(OAUTH, data.access_token).then(() => {
                  UserService.getUserDefaultSetting().then(({data}) => {
                    this.$router.push({name: `LK/${data.START_PAGE_URL}`});
                  });
                });

              }
          )
          .catch(error => {
            this.otherErrors = error;
          });
    },
  }
}
</script>

<style scoped>

</style>
