<template>
  <div>
    <div class="content-tab-wrapper">
      <div class="row">
        <div class="col-md-3 col-lg-2 col-name">Заказчик экспертизы (ОГРН, Наименование)<span
            class="required_color">*</span></div>
        <div class="col-md-9 col-lg-10 col-data col-data-select">
          <select :disabled="!requestItem.UF_IS_EDIT" @change="loadOrgEconomic"
                  v-model="requestItem.UF_COMPANY_ZAKAZ_ID"
                  class="main_input main_select">
            <option v-for="item in companies" :key="item.ID" :value="item.ID">{{ item.UF_OGRN }} {{
                item.UF_NAME
              }}
            </option>
          </select>
          <div class="btn-box" v-if="requestItem.UF_IS_R_EXP_CREATE">
            <br/>
            <button type="submit" @click="showRequesterDialog" v-if="requestItem.UF_COMPANY_ZAKAZ_ID > 0"
                    class="link_style-blue">Редактировать
            </button>
            <button class="link_style-white" @click="showNewRequesterDialog">Добавить заказчика</button>
          </div>
        </div>
      </div>
      <div class="row" v-if="requestItem.UF_COMPANY_ZAKAZ_ID > 0">
        <div class="col-md-3 col-lg-2 col-name">Договор (№, дата)<span
            class="required_color">*</span></div>
        <div class="col-md-9 col-lg-10 col-data col-data-select" v-if="requestItem.UF_IS_R_EXP_CREATE">
          <select :disabled="!requestItem.UF_IS_EDIT" v-model="requestItem.UF_DOGOVOR_EXPERTISE"
                  class="main_input main_select">
            <option v-for="item in company.DOGOVORS_LIST" :key="item.ID" :value="item.ID">{{
                item.UF_NUMBER_REG_EXPERT
              }} {{
                item.UF_DATE_SIGN_EXPERT
              }}
            </option>
          </select>
          <div class="btn-box">
            <br/>
            <button type="submit" @click="showContractDialog" v-if="requestItem.UF_DOGOVOR_EXPERTISE > 0"
                    class="link_style-blue">Редактировать
            </button>
            <button class="link_style-white" @click="showNewContractDialog">Добавить договор</button>
          </div>
        </div>
        <div class="col-md-9 col-lg-10 col-data col-data-select" v-else>
          №{{ requestItem.DOGOVOR.NUMBER }} от {{ requestItem.DOGOVOR.DATE }}
        </div>
      </div>
      <div class="row" v-if="requestItem.UF_COMPANY_ZAKAZ_ID > 0">
        <div class="col-md-3 col-lg-2 col-name">Российский участник
          внешнеэкономической операции
        </div>
        <div class="col-md-9 col-lg-10 col-data" v-if="requestItem.UF_IS_R_EXP_CREATE">
          <select v-model="requestItem.UF_COMPANY_VED_ID" class="main_input main_select">
            <option v-for="item in companiesEconomics.ORG_LIST" :key="item.ID" :value="item.ID">{{
                item.UF_INN
              }} {{
                item.UF_NAME
              }}
            </option>
          </select>
          <div class="btn-box" v-if="companiesEconomics.UF_IS_SHOW_REQUISITES">
            <br/>
            <button type="submit" @click="showRuOrgDialog" v-if="requestItem.UF_COMPANY_VED_ID > 0"
                    class="link_style-blue">Редактировать
            </button>
            <button class="link_style-white" @click="showNewRuOrgDialog">Добавить организацию</button>
          </div>
        </div>
        <div v-else>
          <select disabled v-model="requestItem.COMPANY_VED[0].ID" class="main_input main_select">
            <option :value="requestItem.COMPANY_VED[0].ID">{{ requestItem.COMPANY_VED[0].UF_NAME }}</option>
          </select>
        </div>
      </div>

      <div class="content-tab-wrapper" v-if="companyEconomics && companiesEconomics.UF_IS_SHOW_REQUISITES && requestItem.UF_COMPANY_VED_ID>0">
        <div class="row row_link_title">
          <div class="col-md-12">
            <a @click="showCompaniesEconomics = !showCompaniesEconomics" class="link_title">
              <i class="i_arrow" :class="{i_arrow_hidden: !showCompaniesEconomics}"></i>
              Сведения о российском участнике
            </a>
          </div>
        </div>
        <div class="content-tab-indent" v-if="showCompaniesEconomics">
          <br/><br/>
          <div class="row">
            <div class="col-md-3 col-lg-2 col-name">ИНН</div>
            <div class="col-md-9 col-lg-10 col-data">
              {{ companyEconomics.UF_INN }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-2 col-name">ОГРН</div>
            <div class="col-md-9 col-lg-10 col-data">
              {{ companyEconomics.UF_OGRN }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-2 col-name">Краткое наименование</div>
            <div class="col-md-9 col-lg-10 col-data">
              {{ companyEconomics.UF_NAME }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-2 col-name">Доверенность, выданная заказчику</div>
            <div class="col-md-9 col-lg-10 col-data">
              <select v-model="requestItem.UF_CONFIDENCES_ID" class="main_input main_select">
                <option v-for="item in companyEconomics.CONFIDENCE_DOC_LIST" :key="item.ID" :value="item.ID">Доверенность {{
                    item.UF_PROCURATORY_NUMB
                  }} № {{
                    item.UF_PROCURATORY_DATE_CRT
                  }}
                </option>
              </select>
              <div class="btn-box">
                <br/>
                <button type="submit" @click="showNewAttorneyDialog" v-if="requestItem.UF_CONFIDENCES_ID > 0 && false"
                        class="link_style-blue">Редактировать
                </button>
                <button class="link_style-white" @click="showAttorneyDialog">Добавить доверенность</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-tab-wrapper" v-if="companyEconomics && !companiesEconomics.UF_IS_SHOW_REQUISITES && requestItem.UF_COMPANY_VED_ID>0">
        <div class="row row_link_title">
          <div class="col-md-12">
            <a @click="showCompaniesEconomics = !showCompaniesEconomics" class="link_title">
              <i class="i_arrow" :class="{i_arrow_hidden: !showCompaniesEconomics}"></i>
              Сведения о российском участнике
            </a>
          </div>
        </div>
        <div class="content-tab-indent" v-if="showCompaniesEconomics">
          <br/><br/>
          <div class="row">
            <div class="col-md-3 col-lg-2 col-name">ИНН</div>
            <div class="col-md-9 col-lg-10 col-data">
              {{ companyEconomics.UF_INN }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-2 col-name">ОГРН</div>
            <div class="col-md-9 col-lg-10 col-data">
              {{ companyEconomics.UF_OGRN }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-2 col-name">Краткое наименование</div>
            <div class="col-md-9 col-lg-10 col-data">
              {{ companyEconomics.UF_NAME }}
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-show="false">
        <div class="col-md-3 col-lg-2 col-name">Экспертная организация<span class="required_color">*</span></div>
        <div class="col-md-9 col-lg-10 col-data">
          <select :disabled="!requestItem.UF_IS_EDIT" v-model="requestItem.UF_COMPANY_EXPERT_ID"
                  class="main_input main_select">
            <option v-for="item in directory.expert_org" :key="item.ID" :value="item.ID">{{ item.UF_NAME }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 disable_color" v-show="false" v-if="!this.$route.params.copy && requestItem.DOGOVOR">Договор №
          {{ requestItem.DOGOVOR.NUMBER }} от
          {{ requestItem.DOGOVOR.DATE }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-lg-2 col-name">Внешнеэкономическая операция<span class="required_color">*</span></div>
        <div class="col-md-9 col-lg-10 col-data">
          <div class="checked_row main-inlineBlock stl-pb_10">
            <select :disabled="!requestItem.UF_IS_EDIT" v-model="requestItem.UF_VEO_ID"
                    class="main_input main_select main-inlineBlock">
              <option v-for="item in directory.veo" :key="item.ID" :value="item.ID">{{ item.UF_NAME }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-lg-2 col-name">С
          передачей
        </div>
        <div class="col-md-9 col-lg-10 col-data"><input :disabled="!requestItem.UF_IS_EDIT"
                                                        v-model="requestItem.UF_IS_WITH_GEAR" type="checkbox"
                                                        class="main_check"></div>
      </div>
      <div class="row">
        <div class="col-md-3 col-lg-2 col-name">Страна (страны) операции<span class="required_color">*</span></div>
        <div class="col-md-9 col-lg-10 col-data col-data-select">
          <Multiselect
              @input="setCountry"
              v-if="requestItem.UF_IS_EDIT"
              label="UF_NAME"
              track-by="ID"
              v-model="opCountry"
              :options="directory.country"
              placeholder=""
              :multiple="true"/>
          <div v-else>
            {{ countries }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-lg-2 col-name col-name-center">Дата выдачи заключения</div>
        <div class="col-md-9 col-lg-10 col-data col-data-flex">
          <span class="do_stl">до</span>
          <datepicker
              :disabled="!requestItem.UF_IS_EDIT"
              v-model="requestItem.UF_DATE_ENT_CONCLUSION"
              :language="ru"
              :format="dateFormat"
              input-class="main_input small_calendar small_calendar_mid main-inlineBlock"
          ></datepicker>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-lg-2 col-name">Срочная</div>
        <div class="col-md-9 col-lg-10 col-data"><input :disabled="!requestItem.UF_IS_EDIT"
                                                        v-model="requestItem.UF_IS_IMPORTANT" type="checkbox"
                                                        class="main_check"></div>
      </div>
    </div>
    <!-- content-tab-wrapper -->

    <div class="content-tab-wrapper">
      <div class="row row_link_title">
        <div class="col-md-12">
          <a @click="showMembers = !showMembers" class="link_title">
            <i class="i_arrow" :class="{i_arrow_hidden: !showMembers}"></i>
            Участники
          </a>
        </div>
      </div>
      <div class="content-tab-indent" v-if="showMembers">

        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Покупатель<span class="required_color">*</span></div>
          <div class="col-md-9 col-lg-10 col-data">
            <textarea-autosize
                v-model="requestItem.UF_BUYER"
                :disabled="!requestItem.UF_IS_EDIT"
                class="main_textarea"></textarea-autosize>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Потребитель (конечный
            пользователь)<span class="required_color">*</span>
          </div>
          <div class="col-md-9 col-lg-10 col-data">
            <textarea-autosize
                v-model="requestItem.UF_CONSUMER"
                :disabled="!requestItem.UF_IS_EDIT"
                class="main_textarea"></textarea-autosize>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Грузополучатель</div>
          <div class="col-md-9 col-lg-10 col-data">
            <textarea-autosize
                v-model="requestItem.UF_CARGO_RECEIVE"
                :disabled="!requestItem.UF_IS_EDIT"
                class="main_textarea"></textarea-autosize>
          </div>
        </div>
        <div class="row" v-if="requestItem.COMPANY_ZAKAZ">
          <div class="col-md-3 col-lg-2 col-name">Заказчик экспертизы:</div>
          <div class="col-md-9 col-lg-10 col-data">
            <select disabled v-model="requestItem.COMPANY_ZAKAZ[0].ID" class="main_input main_select">
              <option :value="requestItem.COMPANY_ZAKAZ[0].ID">{{ requestItem.COMPANY_ZAKAZ[0].UF_NAME }}</option>
            </select>
          </div>
        </div>
      </div>
      <br/>
      <div class="row row_link_title">
        <div class="col-md-12">
          <a @click="showDocs = !showDocs" class="link_title">
            <i class="i_arrow" :class="{i_arrow_hidden: !showDocs}"></i>
            Документы-основания
          </a>
        </div>
      </div>
      <div class="content-tab-indent" v-if="showDocs">

        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Вид основного документа<span class="required_color">*</span></div>
          <div class="col-md-9 col-lg-10 col-data"><input :disabled="!requestItem.UF_IS_EDIT" type="text"
                                                          v-model="requestItem.UF_DOC_OSNOVANIE"
                                                          class="main_input">
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">№ основного документа<span class="required_color">*</span></div>
          <div class="col-md-9 col-lg-10 col-data"><input :disabled="!requestItem.UF_IS_EDIT" type="text"
                                                          class="main_input"
                                                          v-model="requestItem.UF_DOC_OSNOVANIE_NUMB">
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Дата основного документа<span class="required_color">*</span></div>
          <div class="col-md-9 col-lg-10 col-data">
            <datepicker
                :disabled="!requestItem.UF_IS_EDIT"
                v-model="requestItem.UF_DOC_OSNOVANIE_DATE"
                :language="ru"
                :format="dateFormat"
                input-class="main_input small_calendar"
            ></datepicker>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-lg-2 col-name">Дополнительные документы - основания списком</div>
          <div class="col-md-9 col-lg-10 col-data">
            <textarea-autosize :disabled="!requestItem.UF_IS_EDIT"
                               v-model="requestItem.UF_DOP_INFO"
                               class="main_textarea"></textarea-autosize>
          </div>
        </div>
      </div>
      <br/>
      <span class="required_color">Все поля, отмеченные * обязательны для заполнения</span>
    </div>

    <transition name="modal-fade" v-if="showContract">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div style="max-width: 700px !important;" class=" modal-container__role modal-add-document"
               role="dialog"
               aria-labelledby="modal-title"
               aria-describedby="modalDescription"
          >
            <header
                class="modal-header main-clear"
            >
              <slot name="header">
                <div class="modal-title">Редактирование договора
                  <button
                      type="button"
                      aria-label="Close modal"
                      class="modal-exit stl-FR reset-button"
                      @click="showContract = false"
                  >
                  </button>
                </div>
              </slot>
            </header>
            <section
                class="modal-body"
                style="height: 500px;overflow-y: scroll;"
            >
              <ContractMainInfo :contract-item="contract"/>
              <div class="btn-box">
                <button type="button" class="link_style-blue" @click="saveContract" v-if="!addNewContract">сохранить
                </button>
                <button type="button" class="link_style-blue" @click="addContract" v-if="addNewContract">Добавить
                  договор
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </transition>

    <transition name="modal-fade" v-if="showAttorney">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div style="max-width: 700px !important;" class=" modal-container__role modal-add-document"
               role="dialog"
               aria-labelledby="modal-title"
               aria-describedby="modalDescription"
          >
            <header
                class="modal-header main-clear"
            >
              <slot name="header">
                <div class="modal-title">Редактирование доверенности
                  <button
                      type="button"
                      aria-label="Close modal"
                      class="modal-exit stl-FR reset-button"
                      @click="showAttorney = false"
                  >
                  </button>
                </div>
              </slot>
            </header>
            <section
                class="modal-body"
                style="height: 500px;overflow-y: scroll;"
            >
              <ContractOrgAttorney :attorney="attorney"/>
              <div class="btn-box">
                <button type="button" class="link_style-blue" @click="saveAttorney" v-if="!addNewAttorney">сохранить
                </button>
                <button type="button" class="link_style-blue" @click="addAttorney" v-if="addNewAttorney">Добавить
                  доверенность
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </transition>

    <transition name="modal-fade" v-if="showOrgItem">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div style="max-width: 700px !important;" class=" modal-container__role modal-add-document"
               role="dialog"
               aria-labelledby="modal-title"
               aria-describedby="modalDescription"
          >
            <header
                class="modal-header main-clear"
            >
              <slot name="header">
                <div class="modal-title">Редактирование организации
                  <button
                      type="button"
                      aria-label="Close modal"
                      class="modal-exit stl-FR reset-button"
                      @click="showOrgItem = false"
                  >
                  </button>
                </div>
              </slot>
            </header>
            <section
                class="modal-body"
                style="height: 500px;overflow-y: scroll;"
            >
              <OrgMainInfo :org-item="orgItem"/>
              <div class="btn-box">
                <button type="button" class="link_style-blue" @click="addRuOrg" v-if="addNewRuOrg">Добавить
                  компанию
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </transition>

    <transition name="modal-fade" v-if="showRequester">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div style="max-width: 700px !important;" class=" modal-container__role modal-add-document"
               role="dialog"
               aria-labelledby="modal-title"
               aria-describedby="modalDescription"
          >
            <header
                class="modal-header main-clear"
            >
              <slot name="header">
                <div class="modal-title">Редактирование заказчика
                  <button
                      type="button"
                      aria-label="Close modal"
                      class="modal-exit stl-FR reset-button"
                      @click="showRequester = false"
                  >
                  </button>
                </div>
              </slot>
            </header>
            <section
                v-if="showCompanyAdd"
                class="modal-body"
                style="height: 300px;overflow-y: scroll;"
            >
              <div class="content-tab-indent">
                <div class="content-tab-wrapper">
                  <div class="content-tab-indent">
                    <div class="row">
                      <div class="col-md-3 col-lg-3 col-name">ИНН<span class="required_color">*</span></div>
                      <div class="col-md-9 col-lg-9 col-data">
                        <input
                            type="text"
                            class="main_input"
                            v-model="newCompany.UF_INN"
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 col-lg-3 col-name">ОГРН<span class="required_color">*</span></div>
                      <div class="col-md-9 col-lg-9 col-data">
                        <input
                            type="text"
                            class="main_input"
                            v-model="newCompany.UF_OGRN"
                        >
                      </div>
                    </div>
                  </div>
                  <div style="color: red">{{ newCompanyError }}</div>
                </div>
                <div class="btn-box">
                  <button type="button" class="link_style-blue" @click="findCompany">Получить данные</button>
                </div>
              </div>
            </section>
            <section
                class="modal-body"
                style="height: 500px;overflow-y: scroll;"
                v-if="!showCompanyAdd"
            >
              <div class="content-tab-wrapper">
                <div class="row row_link_title">
                  <div class="col-md-12">
                    <a @click="showCompanyReq = !showCompanyReq" class="link_title">
                      <i class="i_arrow" :class="{i_arrow_hidden: !showMembers}"></i>
                      Реквизиты заказчика
                    </a>
                  </div>
                </div>

                <div class="content-tab-indent" v-if="showCompanyReq">
                  <div class="content-tab-wrapper">
                    <div class="content-tab-indent">
                      <div class="row">
                        <div class="col-md-3 col-lg-3 col-name">ИНН<span class="required_color">*</span></div>
                        <div class="col-md-9 col-lg-9 col-data">
                          <input
                              type="text"
                              class="main_input"
                              v-model="company.UF_INN"
                              disabled
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 col-lg-3 col-name">ОГРН<span class="required_color">*</span></div>
                        <div class="col-md-9 col-lg-9 col-data">
                          <input
                              type="text"
                              class="main_input"
                              v-model="company.UF_OGRN"
                              disabled
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 col-lg-3 col-name">Полное наименование <br> (в соответствии с Уставом)<span
                            class="required_color">*</span>
                        </div>
                        <div class="col-md-9 col-lg-9 col-data">
                  <textarea class="main_textarea"
                            v-model="company.UF_FULL_NAME">
                  </textarea>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 col-lg-3 col-name">Краткое наименование<span
                            class="required_color">*</span></div>
                        <div class="col-md-9 col-lg-9 col-data">
                          <input type="text"
                                 class="main_input"
                                 v-model="company.UF_NAME"
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 col-lg-3 col-name">Дата внесения записи в ЕГРЮЛ<span
                            class="required_color">*</span>
                        </div>
                        <div class="col-md-9 col-lg-9 col-data">
                          <datepicker
                              v-model="company.UF_DATE_EGRYUL"
                              :language="ru"
                              :format="dateFormat"
                              input-class="main_input small_calendar small_calendar_mid main-inlineBlock"
                          ></datepicker>

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 col-lg-3 col-name">Дата государственной регистрации<span
                            class="required_color">*</span>
                        </div>
                        <div class="col-md-9 col-lg-9 col-data">
                          <datepicker
                              v-model="company.UF_DATE_GOS_REGISTR"
                              :language="ru"
                              :format="dateFormat"
                              input-class="main_input small_calendar small_calendar_mid main-inlineBlock"
                          ></datepicker>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-md-3 col-lg-3 col-name">Брокер</div>
                        <div class="col-md-9 col-lg-9 col-data">
                          <input type="checkbox"

                                 class="main_check"
                                 v-model="company.UF_IS_BROKER">
                        </div>
                      </div>
                    </div>
                    <!-- content-tab-indent -->
                  </div>

                  <div class="content-tab-wrapper">
                    <div class="row row_link_title">
                      <div class="col-md-12">
                        <a @click="showAddressesPhones = !showAddressesPhones" class="link_title">
                          <i class="i_arrow" :class="{i_arrow_hidden: !showAddressesPhones}"></i>
                          Адреса и телефоны
                        </a>
                      </div>
                    </div>
                    <div class="content-tab-indent" v-if="showAddressesPhones">
                      <div class="row">
                        <div class="col-md-3 col-lg-3 col-name">Юридический<span class="required_color">*</span></div>
                        <div class="col-md-9 col-lg-9 col-data">
                <textarea class="main_textarea"
                          v-model="company.UF_ADDRES_JUR">
                </textarea>

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 col-lg-3 col-name">Фактический<span class="required_color">*</span></div>
                        <div class="col-md-9 col-lg-9 col-data">
                <textarea class="main_textarea"
                          v-model="company.UF_ADDRES_FACT">
                </textarea>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 col-lg-3 col-name">Почтовый<span class="required_color">*</span></div>
                        <div class="col-md-9 col-lg-9 col-data">
                <textarea class="main_textarea"
                          v-model="company.UF_ADDRES_POCHTA">
                </textarea>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 col-lg-3 col-name">Телефон<span class="required_color">*</span></div>
                        <div class="col-md-9 col-lg-9 col-data"><input type="text"
                                                                       v-model="company.UF_PHONES[0]"
                                                                       class="main_input">
                        </div>
                      </div>
                    </div>
                    <!-- content-tab-indent -->
                  </div>
                </div>

                <div class="content-tab-wrapper">
                  <div class="row row_link_title">
                    <div class="col-md-12">
                      <a @click="showBankDetails = !showBankDetails" class="link_title">
                        <i class="i_arrow" :class="{i_arrow_hidden: !showBankDetails}"></i>
                        Банковские реквизиты
                      </a>
                    </div>
                  </div>
                  <div class="content-tab-indent" v-if="showBankDetails">
                    <div class="row">
                      <div class="col-md-3 col-lg-3 col-name">БИК банка<span class="required_color">*</span></div>
                      <div class="col-md-9 col-lg-9 col-data">
                        <input type="text"
                               class="main_input"
                               v-mask="'#########'"
                               placeholder="_________"
                               v-model="company.UF_BANK_BIK">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 col-lg-3 col-name">ИНН банка<span class="required_color">*</span></div>
                      <div class="col-md-9 col-lg-9 col-data">
                        <input type="text"
                               class="main_input"
                               v-mask="'##########'"
                               placeholder="__________"
                               v-model="company.UF_BANK_INN">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 col-lg-3 col-name">КПП банка<span class="required_color">*</span></div>
                      <div class="col-md-9 col-lg-9 col-data">
                        <input type="text"
                               class="main_input"
                               v-mask="'#########'"
                               placeholder="_________"
                               v-model="company.UF_BANK_KPP">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 col-lg-3 col-name">Название банка<span class="required_color">*</span></div>
                      <div class="col-md-9 col-lg-9 col-data">
                        <input type="text"
                               class="main_input"
                               v-model="company.UF_BANK_NAME">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 col-lg-3 col-name">Расчетный счет<span class="required_color">*</span></div>
                      <div class="col-md-9 col-lg-9 col-data">
                        <input type="text"
                               class="main_input"
                               v-mask="'407##810############'"
                               placeholder="407__810____________"
                               v-model="company.UF_RASH_SHET">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 col-lg-3 col-name">Корреспондентский счет банка<span
                          class="required_color">*</span></div>
                      <div class="col-md-9 col-lg-9 col-data">
                        <input type="text"
                               class="main_input"
                               v-mask="'####################'"
                               placeholder="____________________"
                               v-model="company.UF_BANK_KOR_SCHET">
                      </div>
                    </div>
                  </div>
                  <!-- content-tab-indent -->
                </div>
                <p class="required_color stl-pb_30">Все поля, отмеченные * - обязательны для заполнения</p>
                <!-- content-tab-wrapper -->
                <div class="btn-box">
                  <button type="button" class="link_style-blue" @click="saveCompany" v-if="!addNewRequester">сохранить
                  </button>
                  <button type="button" class="link_style-blue" @click="addCompany" v-if="addNewRequester">Добавить
                    заказчика
                  </button>
                </div>
              </div>


            </section>

          </div>
        </div>
      </div>
    </transition>

    <!-- content-tab-wrapper -->
    <CardExpertDocs :page="1" v-if="!this.$route.params.copy && this.requestItem.ID > 0"></CardExpertDocs>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {mapGetters} from "vuex";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import {ru} from "vuejs-datepicker/dist/locale";
import CardExpertDocs from "@/components/cabinet/request/CardExpertDocs";
import {Funcs} from "@/common/funcs";
import {RequestService} from "@/common/api.service";
import ContractMainInfo from "@/components/cabinet/request/ContractMainInfo";
import ContractOrgAttorney from "@/components/cabinet/request/ContractOrgAttorney";
import OrgMainInfo from "@/components/cabinet/request/OrgMainInfo";

export default {
  name: "CardExpertContent",
  data() {
    return {
      ru: ru,
      showRequester: false,
      showMembers: true,
      showCompanyReq: true,
      showAddressesPhones: true,
      showBankDetails: true,
      showDocs: true,
      addNewRequester: false,
      addNewContract: false,
      showContract: false,
      showCompanyAdd: false,
      showCompaniesEconomics: false,
      showAttorney: false,
      addNewAttorney: false,
      addNewRuOrg: false,
      showOrgItem: false,
      opCountry: [],
      companies: [],
      newRequester: {
        UF_PHONES: []
      },
      newCompany: {},
      newCompanyError: "",
      newContract: {},
      newAttorney: {},
      newOrg: {},
      toasterErrors: [],
      companiesEconomics: []
    };
  },
  components: {OrgMainInfo, ContractOrgAttorney, ContractMainInfo, CardExpertDocs, Datepicker, Multiselect},
  async mounted() {
    await this.$store.dispatch('fetchDirectories', [
      "expert_org",
      "veo",
      "country"
    ]);
    this.opCountry = []
    for (let i = 0; i < this.requestItem.UF_COUNTRY_ID.length; i++) {
      let v = {
        ID: this.requestItem.UF_COUNTRY_ID[i]
      }
      for (let d = 0; d < this.directory.country.length; d++) {
        if (this.directory.country[d].ID === this.requestItem.UF_COUNTRY_ID[i]) {
          v.UF_NAME = this.directory.country[d].UF_NAME
        }
      }
      this.opCountry.push(v)
    }
    this.loadCompanies()
  },
  computed: {
    countries() {
      let c = []
      if (this.directory.country.length && this.requestItem.UF_COUNTRY_ID.length) {
        for (let i = 0; i < this.requestItem.UF_COUNTRY_ID.length; i++) {
          c.push(Funcs.getDirectoryValue(this.directory.country, this.requestItem.UF_COUNTRY_ID[i]).UF_NAME)
        }
      }
      return c.join(', ')
    },
    company() {
      if (!this.requestItem.UF_COMPANY_ZAKAZ_ID || this.addNewRequester) {
        return this.newRequester
      }
      return this.companies.filter(el => el.ID === this.requestItem.UF_COMPANY_ZAKAZ_ID)[0]
    },
    companyEconomics() {
      if (!this.requestItem.UF_COMPANY_VED_ID || !this.companiesEconomics || !this.companiesEconomics.ORG_LIST) {
        return null
      }
      return this.companiesEconomics.ORG_LIST.filter(el => el.ID === this.requestItem.UF_COMPANY_VED_ID)[0]
    },
    contract() {
      if (!this.requestItem.UF_DOGOVOR_EXPERTISE || this.addNewContract || !this.company) {
        return this.newContract
      }
      return this.company.DOGOVORS_LIST.filter(el => el.ID === this.requestItem.UF_DOGOVOR_EXPERTISE)[0]
    },
    attorney() {
      if (!this.requestItem.UF_CONFIDENCES_ID || this.addNewAttorney) {
        return this.newAttorney
      }
      return this.companyEconomics.CONFIDENCE_DOC_LIST.filter(el => el.ID === this.requestItem.UF_CONFIDENCES_ID)[0]
    },
    orgItem() {
      if (!this.requestItem.UF_COMPANY_VED_ID || this.addNewRuOrg) {
        return this.newOrg
      }
      return this.companyEconomics
    },
    ...mapGetters([
      "currentUser",
      "requestItem",
      "isRequestsLoading",
      "directory"
    ]),
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    showAttorneyDialog() {
      this.addNewAttorney = true
      this.showAttorney = true
    },
    showNewAttorneyDialog() {
      this.addNewAttorney = false
      this.showAttorney = true
    },
    showRequesterDialog() {
      this.addNewRequester = false
      this.showCompanyAdd = false
      this.showRequester = true
    },
    showNewRequesterDialog() {
      this.addNewRequester = true
      this.showCompanyAdd = true
      this.newCompanyError = ""
      this.showRequester = true
    },
    showContractDialog() {
      this.addNewContract = false
      this.showContract = true
    },
    showNewContractDialog() {
      this.addNewContract = true
      this.showContract = true
    },
    showNewRuOrgDialog() {
      this.showOrgItem = true
      this.addNewRuOrg = true
    },
    showRuOrgDialog() {
      this.showOrgItem = true
      this.addNewRuOrg = false
    },
    loadOrgEconomic() {
      RequestService.getListOrgEconomicActivityByExpert(this.requestItem.UF_COMPANY_ZAKAZ_ID).then(({data}) => {
        this.companiesEconomics = data
      }).catch(() => 'ERROR')
    },
    findCompany() {
      this.newCompanyError = ""
      RequestService.getOrgByOGRN_INN(this.newCompany).then(({data}) => {
        if (data.error) {
          this.newCompanyError = data.errorAr[0]
        } else {
          this.newRequester = data.ORG_INFO
          this.showCompanyAdd = false
        }
      }).catch(() => 'ERROR')
    },
    saveCompany() {
      RequestService.setOrgProfileInfoByExpertOrg(this.company).then(({data}) => {
        if (data.error) {
          data.errorAr.forEach(function (err) {
            this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
                err.TEXT,
                {
                  theme: "bubble",
                  position: "bottom-right",
                  duration: null,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    },
                    {
                      text: "Закрыть все",
                      onClick: () => {
                        this.toasterErrors.forEach(function (err) {
                          err.goAway(0);
                        });
                      }
                    }
                  ]
                }
            );
          }, this);
          return
        }
        this.loadCompanies()
        this.showRequester = false
      }).catch(() => 'ERROR')
    },
    saveContract() {
      RequestService.editDogovor(this.contract.ID, this.contract).then(({data}) => {
        if (data.error) {
          data.errorAr.forEach(function (err) {
            this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
                err.TEXT,
                {
                  theme: "bubble",
                  position: "bottom-right",
                  duration: null,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    },
                    {
                      text: "Закрыть все",
                      onClick: () => {
                        this.toasterErrors.forEach(function (err) {
                          err.goAway(0);
                        });
                      }
                    }
                  ]
                }
            );
          }, this);
          return
        }
        this.loadCompanies()
        this.showContract = false
      }).catch(() => 'ERROR')
    },
    addCompany() {
      RequestService.addOrgProfileInfoByExpertOrg(this.company).then(({data}) => {
        if (data.error) {
          data.errorAr.forEach(function (err) {
            this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
                err.TEXT,
                {
                  theme: "bubble",
                  position: "bottom-right",
                  duration: null,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    },
                    {
                      text: "Закрыть все",
                      onClick: () => {
                        this.toasterErrors.forEach(function (err) {
                          err.goAway(0);
                        });
                      }
                    }
                  ]
                }
            );
          }, this);
          return
        }
        if (data.ID) {
          this.requestItem.UF_COMPANY_ZAKAZ_ID = data.ID
          this.loadCompanies()
          this.showRequester = false
        }
      }).catch(() => 'ERROR')
    },
    addContract() {
      RequestService.createDogovor(this.company).then(({data}) => {
        if (data.ID) {
          this.requestItem.UF_COMPANY_ZAKAZ_ID = data.ID
          this.loadCompanies()
        }
      }).catch(() => 'ERROR')
    },
    addAttorney() {
      RequestService.addOrgEconomicActivityConfidenceByExpert(this.requestItem.UF_COMPANY_ZAKAZ_ID, this.companyEconomics.ID, this.attorney).then(({data}) => {
        if (data.error) {
          data.errorAr.forEach(function (err) {
            this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
                err.TEXT,
                {
                  theme: "bubble",
                  position: "bottom-right",
                  duration: null,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    },
                    {
                      text: "Закрыть все",
                      onClick: () => {
                        this.toasterErrors.forEach(function (err) {
                          err.goAway(0);
                        });
                      }
                    }
                  ]
                }
            );
          }, this);
          return
        }
        if (data.ID) {
          //this.requestItem.UF_COMPANY_ZAKAZ_ID = data.ID
          this.loadOrgEconomic()
          this.addNewAttorney = false
          this.showAttorney = false
        }
      }).catch(() => 'ERROR')
    },
    saveAttorney() {
      RequestService.setOrgEconomicActivityByExpert(this.requestItem.UF_COMPANY_ZAKAZ_ID, this.companyEconomics.ID, this.attorney).then(({data}) => {
        if (data.error) {
          data.errorAr.forEach(function (err) {
            this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
                err.TEXT,
                {
                  theme: "bubble",
                  position: "bottom-right",
                  duration: null,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    },
                    {
                      text: "Закрыть все",
                      onClick: () => {
                        this.toasterErrors.forEach(function (err) {
                          err.goAway(0);
                        });
                      }
                    }
                  ]
                }
            );
          }, this);
          return
        }
        if (data.ID) {
          //this.requestItem.UF_COMPANY_ZAKAZ_ID = data.ID
          this.loadOrgEconomic()
          this.addNewAttorney = false
          this.showAttorney = false
        }
      }).catch(() => 'ERROR')
    },
    addRuOrg() {
      RequestService.addOrgEconomicActivityByExpert(this.requestItem.UF_COMPANY_ZAKAZ_ID, this.newOrg).then(({data}) => {
        if (data.error) {
          data.errorAr.forEach(function (err) {
            this.toasterErrors[this.toasterErrors.length] = this.$toasted.show(
                err.TEXT,
                {
                  theme: "bubble",
                  position: "bottom-right",
                  duration: null,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    },
                    {
                      text: "Закрыть все",
                      onClick: () => {
                        this.toasterErrors.forEach(function (err) {
                          err.goAway(0);
                        });
                      }
                    }
                  ]
                }
            );
          }, this);
          return
        }
        if (data.ID) {
          this.requestItem.UF_VEO_ID = data.ID
          this.loadOrgEconomic()
          this.showOrgItem = false
          this.newOrg = {}
        }
      }).catch(() => 'ERROR')
    },
    loadCompanies() {
      RequestService.getListAccessExportOrgForExpert(this.currentUser.COMPANY_ID).then(({data}) => {
        this.companies = data
      }).catch(() => 'ERROR')
    },
    setCountry() {
      this.requestItem.UF_COUNTRY_ID = []
      for (let i = 0; i < this.opCountry.length; i++) {
        this.requestItem.UF_COUNTRY_ID.push(this.opCountry[i].ID)
      }
    }
  }
}
</script>

<style scoped>

</style>
