<template>
  <div class="content-indent-wrapper">
    <div class="content-indent">
      <div class="page-top-title">
        <div class="page-top-title__item">
          <h2 class="content-title">Реестр запросов кп</h2>
        </div>
        <div class="page-top-title__item text-right">
        </div>
      </div>
      <div class="content-tab-area">
        <div class="content-tab__panel tab_active">
          <div class="container-fluid">
            <div class="content-tab-wrapper">
              <div class="content-tab-indent">
                <div class="control-panel" v-if="!manager">
                  <div class="control-panel__item">
                    <router-link :to="{name: 'LK/response-kp/create'}"
                                 class="control-panel__link control-add_link">
                      добавить
                    </router-link>
                  </div>
                  <div class="control-panel__item">
                    <a @click="showCopyModal=true" class="control-panel__link control-copy_link">
                      добавить с копированием
                    </a>
                  </div>
                  <div class="control-panel__item">
                    <a @click="deleteKP" title="Удалить запрос" class="control-delete_link"/>
                  </div>
                  <div class="control-panel__item">
                    <div class="control-parent_item">
                      <a @click="showFilterModal=true"
                         title="Настройка фильтра"
                         class="control-panel__link control-funnel_link"
                      />
                    </div>
                    <div class="control-parent_item">
                      <a @click="showGroupModal=true"
                         title="Группировка"
                         class="control-panel__link control-diagram_link"
                      />
                    </div>
                  </div>
                  <div class="control-panel__search">
                    <select v-model="filter.status"
                            @change="fetchKPs"
                            class="small_search"
                            style="background: none">
                      <option value="">Все статусы</option>
                      <option v-for="item in directory.status_zapros_kp" :key="item.ID" :value="item.ID">
                        --{{ item.UF_NAME }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="control-panel" v-else>
                  <div class="control-panel__item">
                    <div class="control-parent_item">
                      <a @click="showFilterModal=true"
                         title="Настройка фильтра"
                         class="control-panel__link control-funnel_link"
                      />
                    </div>
                    <div class="control-parent_item">
                      <a @click="showGroupModal=true"
                         title="Группировка"
                         class="control-panel__link control-diagram_link"
                      />
                    </div>
                  </div>
                  <div class="control-panel__search">
                    <select v-model="filter.status"
                            @change="fetchKPs"
                            class="small_search"
                            style="background: none">
                      <option value="">Все статусы</option>
                      <option v-for="item in directory.status_zapros_kp" :key="item.ID" :value="item.ID">
                        --{{ item.UF_NAME }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="table-responsive production-area" v-if="kp && kp.settings && !isKPLoading">
                  <table class="production-table_child">
                    <thead>
                    <tr>
                      <th>
                        <input type="checkbox" v-model="checkAllBox" @click="checkAll">
                      </th>
                      <th v-for="item in kp.settings.reestrFields"
                          :key="item.NAME"
                          class="mob-cell-w_120"
                      >
                        <div class="table-th-wrap">
                          <span class="table-th-wrap__name">{{ item.NAME }}</span>
                          <i v-if="item.SORT"
                             @click="setOrder(item.FIELD)"
                             class="i-panel_orient" :class="{
                                                orient_active: sortColumn===item.FIELD,
                                                asc:  sortOrder==='ASC',
                                                desc: sortOrder==='DESC',
                                            }"
                          >
                          </i>
                        </div>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="" v-for="item in kp.items" v-bind:key="item.ID">
                      <td>
                        <input type="checkbox" @click="checkItem(item.ID)" :checked="isChecked(item.ID)"/>
                      </td>
                      <td v-for="field in kp.settings.reestrFields" :key="field.NAME">
                        <router-link v-if="field.IS_LINK===1"
                                     :to="{name: 'LK/response-kp/edit', params: { id: item.ID }}"
                        >
                          {{ item[field.FIELD] }}
                        </router-link>
                        <span v-else-if="field.TYPE==='normal'">{{ item[field.FIELD] }}</span>
                        <span v-else-if="field.TYPE==='composite_normal'">{{ compositeValue(item, field.FIELD) }}</span>
                        <span v-else-if="field.TYPE==='composite_array'">
                          <span v-for="object in compositeArrayValue(item, field.FIELD)" :key="object">
                            {{ object }}
                          </span>
                        </span>
                      </td>
                    </tr>
                    <tr class="" v-for="(gitem, gindex) in glist" v-bind:key="gitem.ID">
                      <td v-if="!gitem.isItem && !gitem.hide"
                          :colspan="kp.settings.reestrFields.length+1"
                      >
                        <span :style="{marginLeft: gitem.deep*10+'px'}"></span>
                        <a style="color:black;font-weight: bold;" @click="toggleGroup(gindex)">
                          <span class="row-parent_switch" :class="{'row-parent_switch_close': !gitem.hideNext,}"></span>
                          {{ gitem.name }}
                        </a>
                      </td>
                      <td v-if="gitem.isItem && !gitem.hide">
                        <span :style="{marginLeft: gitem.deep*10+'px'}"></span>
                        <input type="checkbox"
                               @click="checkItem(gitem.it.ID)"
                               :checked="isChecked(gitem.it.ID)"
                        />
                      </td>
                      <template v-if="gitem.isItem && !gitem.hide">
                        <td v-for="field in kp.settings.reestrFields" :key="field.NAME">
                          <router-link v-if="field.FIELD==='UF_REG_NUMBER'"
                                       :to="{name: 'LK/response-kp/edit', params: { id: gitem.it.ID }}"
                          >
                            {{ gitem.it.UF_REG_NUMBER }}
                          </router-link>
                          <span v-else-if="field.TYPE==='normal'">
                            {{ gitem.it[field.FIELD] }}
                          </span>
                          <span v-else-if="field.TYPE==='composite_normal'">
                            {{ compositeValue(gitem.it, field.FIELD) }}
                          </span>
                          <span v-else-if="field.TYPE==='composite_array'">
                            <span v-for="object in compositeArrayValue(gitem.it, field.FIELD)" :key="object">
                              {{ object }}
                            </span>
                          </span>
                        </td>
                      </template>
                    </tr>
                    </tbody>
                  </table>
                  <div class="main-pagination table-pagination">
                    <Pagination
                        :pages="pages"
                        :currentPage.sync="currentPage"
                    />
                    <span class="pagination_all">Всего: {{ kp.counts }}</span>
                    <span>Выводить по: </span>
                    <select class="pagination__select" v-model="itemsPerPage">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-if="showCopyModal"
                   class="modal fade in show"
                   tabindex="-1"
                   role="dialog"
                   aria-labelledby="myModalLabel"
              >
                <div class="modal-dialog modal-middle" role="document">
                  <div class="modal-content">
                    <div v-if="showCopyModal"
                         class="modal-add-document modal-full"
                    >
                      <div class="modal-header main-clear">
                        <span class="modal-title">укажите запрос для копирования</span>
                        <a @click="showCopyModal=false" class="modal-exit stl-FR"></a>
                      </div>
                      <div class="modal-body">
                        <div class="modal_row main-clear">
                          <div class="table-responsive">
                            <table class="filter-table">
                              <tbody>
                              <tr>
                                <td>
                                  <select v-model="copyID"
                                          class="main_input main_select filter-input">
                                    <option v-for="o in copyData"
                                            :key="o.ID"
                                            :value="o.ID">
                                      запрос Рег. № {{ o.UF_NUMBER }} от {{ o.UF_DATE_CREATE }}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="modal_row main-clear">
                          <router-link v-if="copyID"
                                       class="link_style-blue modal-full_wid"
                                       :to="{name: 'LK/response-kp/copy', params: { id: copyID, copy:1 }}"
                          >
                            Создать
                          </router-link>
                          <div class="link_style-white modal-full_wid" @click="showCopyModal = false">
                            отмена
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="showGroupModal"
                   class="modal fade in show"
                   tabindex="-1"
                   role="dialog"
                   aria-labelledby="myModalLabel"
              >
                <div class="modal-dialog modal-middle" role="document">
                  <div class="modal-content">
                    <div class="modal-add-document">
                      <div class="modal-header main-clear">
                        <span class="modal-title">настройка группировки</span>
                        <a @click="showGroupModal=false" class="modal-exit stl-FR"></a>
                      </div>
                      <div class="modal-body">
                        <div class="modal_row main-clear">
                          <div class="table-responsive">
                            <table class="filter-table">
                              <tbody>
                              <tr v-for="(group, gi) in groups" :key="group.name">
                                <td>
                                  <select v-model="group.index"
                                          class="main_input main_select filter-input"
                                  >
                                    <option v-for="item in groupFields(gi, kp.settings.groupFields)"
                                            :key="item.NAME"
                                            :value="item.POS"
                                    >
                                      {{ item.NAME }}
                                    </option>
                                  </select>
                                </td>
                                <td>уровень {{ gi + 1 }}</td>
                                <td class="text-center">
                                  <a @click="groups.splice(gi, 1)">
                                    <i class="control-delete_link"/>
                                  </a>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="modal_row">
                          <a v-if="groupFields(-1, kp.settings.groupFields).length > 0"
                             class="control-panel__link control-add_link"
                             @click="groups.push({})">
                            добавить уровень группировки
                          </a>
                        </div>
                        <div class="modal_row main-clear">
                          <div class="link_style-blue modal-full_wid" @click="fetchKPs">
                            применить
                          </div>
                          <div class="link_style-blue link_style-reset modal-full_wid" @click="clearGroups">
                            сбросить группировку
                          </div>
                          <div class="link_style-white modal-full_wid" @click="showGroupModal = false">
                            отмена
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="showFilterModal"
                   class="modal fade in show"
                   tabindex="-1"
                   role="dialog"
                   aria-labelledby="myModalLabel"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-add-document modal-full">
                      <div class="modal-header main-clear">
                        <span class="modal-title">настройка фильтра</span>
                        <a @click="showFilterModal=false" class="modal-exit stl-FR"></a>
                      </div>
                      <div class="modal-body">
                        <div class="modal_row main-clear">
                          <div class="table-responsive">
                            <table class="filter-table">
                              <tbody>
                              <tr v-for="(filter, fi) in filters" :key="filter.name">
                                <td>
                                  <select @change="changeFilterIndex(filter)"
                                          v-model="filter.index"
                                          class="main_input main_select filter-input">
                                    <option v-for="(item, index) in kp.settings.filterFields"
                                            :key="item.NAME"
                                            :value="index"
                                    >
                                      {{ item.NAME }}
                                    </option>
                                  </select>
                                </td>
                                <td>
                                  <select v-model="filter.operation"
                                          class="main_input main_select filter-input">
                                    <option v-for="(item, index) in kp.settings.filterFields[filter.index].OPERATION"
                                            :key="item.NAME"
                                            :value="index"
                                    >
                                      {{ item.NAME }}
                                    </option>
                                  </select>
                                </td>
                                <td style="width: 250px">
                                  <input v-if="kp.settings.filterFields[filter.index].FIELD_TYPE==='string'"
                                         type="text" class="main_input filter-input"
                                         v-model="filter.value"
                                  />
                                  <input
                                      v-if="kp.settings.filterFields[filter.index].FIELD_TYPE==='string' && kp.settings.filterFields[filter.index].OPERATION[filter.operation].CNT_VALUE===2"
                                      style="margin-top: 5px"
                                      type="text" class="main_input filter-input"
                                      v-model="filter.value2"
                                  />
                                  <Multiselect v-if="kp.settings.filterFields[filter.index].FIELD_TYPE==='multiselect'"
                                               label="UF_NAME"
                                               :loading="!filter.listRendered"
                                               :limit="1"
                                               :limitText="filterItemText"
                                               track-by="ID"
                                               v-model="filter.multivalue"
                                               :options="filter.objects"
                                               :multiple="true" placeholder=""
                                  />
                                  <Multiselect
                                      v-if="kp.settings.filterFields[filter.index].FIELD_TYPE==='multiselect' && kp.settings.filterFields[filter.index].OPERATION[filter.operation].CNT_VALUE===2"
                                      label="UF_NAME"
                                      :loading="!filter.listRendered"
                                      :limit="1"
                                      :limitText="filterItemText"
                                      track-by="ID"
                                      v-model="filter.multivalue2"
                                      :options="filter.objects"
                                      :multiple="true" placeholder=""
                                      style="margin-top: 5px"
                                  />
                                  <datepicker v-if="kp.settings.filterFields[filter.index].FIELD_TYPE==='datetime'"
                                              v-model="filter.value"
                                              :language="ru"
                                              :format="dateFormat" style="width: 100%"
                                              input-class="main_input filter-input"
                                  />
                                  <datepicker
                                      v-if="kp.settings.filterFields[filter.index].FIELD_TYPE==='datetime' && kp.settings.filterFields[filter.index].OPERATION[filter.operation].CNT_VALUE===2"
                                      v-model="filter.value2"
                                      :language="ru"
                                      :format="dateFormat"
                                      style="margin-top: 5px;width: 100%"
                                      input-class="main_input filter-input"
                                  />
                                  <select v-if="kp.settings.filterFields[filter.index].FIELD_TYPE==='select'"
                                          type="text"
                                          class="main_input filter-input"
                                          v-model="filter.value"
                                  >
                                    <option v-for="item in filter.objects"
                                            :key="item.ID"
                                            :value="item.ID"
                                    >
                                      {{ item.UF_NAME }}
                                    </option>
                                  </select>
                                </td>
                                <td>
                                  <select v-model="filter.logic"
                                          class="main_input main_select filter-input">
                                    <option v-for="(item, index) in kp.settings.filterFields[filter.index].LOGIC"
                                            :key="item.NAME"
                                            :value="index"
                                    >
                                      {{ item.NAME }}
                                    </option>
                                  </select>
                                </td>
                                <td class="text-center">
                                  <a @click="filters.splice(fi, 1)">
                                    <i class="control-delete_link"/>
                                  </a>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="modal_row">
                          <a class="control-panel__link control-add_link"
                             @click="filters.push({index:0, operation:0, logic: 0, value: '', value2: '', objects:[], listRendered: false, multivalue:[], multivalue2:[]})">
                            добавить фильтр
                          </a>
                        </div>
                        <div class="modal_row main-clear">
                          <div class="link_style-blue modal-full_wid" @click="fetchKPs">
                            применить
                          </div>
                          <div class="link_style-blue link_style-reset modal-full_wid" @click="clearFilters()">
                            сбросить фильтры
                          </div>
                          <div class="link_style-white modal-full_wid" @click="showFilterModal=false">
                            отмена
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import Pagination from "@/components/site/Pagination";
import moment from "moment";
import {ru} from "vuejs-datepicker/dist/locale";
import ApiService, {KPService} from "@/common/api.service";
import Vue from "vue";
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";

export default {
  name: "RequestKp",
  components: {Pagination, Datepicker, Multiselect},
  data() {
    return {
      metaTitle: '',
      itemsPerPage: 20,
      currentPage: 1,
      sortColumn: "UF_DATE_CREATE",
      sortOrder: "DESC",
      ru: ru,
      manager: undefined,
      showDownloadKPModal: false,
      showFilterModal: false,
      showGroupModal: false,
      showCopyModal: false,
      copyID: 0,
      KPDate: null,
      showKPModal: false,
      rulesDate: null,
      rulesErr: null,
      textQuestion: "",
      checkedItems: [],
      checkAllBox: false,
      filter: {
        status: "",
        country: ""
      },
      groups: [],
      filters: [],
      applyFilters: [],
      applyGroups: [],
      glist: [],
      copyData: [],
      tosterErrors: [],
    };
  },

  created() {
    this.manager = this.$store.getters.currentUser.ACTIVE_ROLE === 10;
  },

  async mounted() {
    await this.fetchKPs();
    await this.$store.dispatch('fetchDirectory', [
      "status_zapros_kp"
    ]);
  },

  computed: {
    ...mapGetters([
      "kp",
      "isKPLoading",
      "directory"
    ]),
    listConfig() {
      const q = {
        page: this.currentPage,
        limit: this.itemsPerPage,
        filter: "",
        group: [],
        filterStatus: null,
        filterCountry: null,
        filterPeriod: {}
      };
      if (this.sortColumn !== "") {
        let sort = {
          column: "",
          order: ""
        };
        sort.column = this.sortColumn;
        sort.order = this.sortOrder;
        q.sort = sort;
      }
      if (this.filter.status > 0) {
        q.filterStatus = this.filter.status
      }
      if (this.filter.country > 0) {
        q.filterCountry = this.filter.country
      }
      let filterarr = []
      this.filters.forEach(function (filter) {
        filterarr.push({
          FIELD: this.kp.settings.filterFields[filter.index].FIELD,
          OPERATION: this.kp.settings.filterFields[filter.index].OPERATION[filter.operation].CODE,
          VALUE: filter.value, VALUE2: filter.value2,
          LOGIC: this.kp.settings.filterFields[filter.index].LOGIC[filter.logic].CODE,
          RUNTIME: this.kp.settings.filterFields[filter.index].RUNTIME,
          CODE_RUNTIME: this.kp.settings.filterFields[filter.index].CODE_RUNTIME
        })
      }, this);
      q.filter = ""
      if (filterarr.length) {
        q.filter = Buffer.from(JSON.stringify(filterarr)).toString("base64");
      }
      if (this.filters.length > 0) {
        Vue.set(this, 'applyFilters', JSON.parse(JSON.stringify(this.filters)))
      }

      this.groups.forEach(function (group) {
        q.group.push(this.kp.settings.groupFields[group.index].FIELD)
      }, this);

      if (this.groups.length > 0) {
        Vue.set(this, 'applyGroups', JSON.parse(JSON.stringify(this.groups)))
      }
      return q;
    },
    pages() {
      if (this.isKPLoading || this.kp.counts <= this.itemsPerPage) {
        return [];
      }
      return [
        ...Array(Math.ceil(this.kp.counts / this.itemsPerPage)).keys()
      ].map(e => e + 1);
    }
  },

  watch: {
    currentPage(newValue) {
      this.listConfig.page = newValue;
      this.fetchKPs();
    },

    itemsPerPage(newValue) {
      this.listConfig.limit = newValue;
      this.fetchKPs();
    }
  },

  filters: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },

  methods: {
    setOrder(column) {
      if (this.sortColumn !== column) {
        this.sortOrder = "ASC";
      } else {
        if (this.sortOrder === "DESC") {
          this.sortOrder = "";
          this.sortColumn = "";
        } else {
          this.sortOrder = "DESC";
        }
      }
      this.sortColumn = column;
      this.fetchKPs();
    },

    fetchKPs() {
      this.$store.dispatch('fetchKPs', this.listConfig).then(() => {
        document.title = unescape(this.kp.META_TITLE);
        this.showFilterModal = false
        this.showGroupModal = false
        if (this.kp.group.length > 0) {
          this.glist = [];
          this.appendq(this.kp.group, 0);
        }
        KPService.getListCopyKP().then(({data}) => {
          this.copyData = data;
        }, this)
      }, this)
      this.checkedItems = [];
    },

    appendq(items, deep) {
      items.forEach(function (item) {
        this.glist.push({name: item.GROUP_NAME, deep: deep, isItem: false, hide: false, hideNext: false})
        if (item.GROUP_CHILDS.length) {
          this.appendq(item.GROUP_CHILDS, deep + 1)
        } else {
          item.GROUP_CHILDS.items.forEach(function (it) {
            this.glist.push({name: item.GROUP_NAME, deep: deep, isItem: true, it: it, hide: false})
          }, this)
        }
      }, this)
    },

    toggleGroup(ind) {
      let isStarted = false
      let deep = 0
      let hide = false
      this.glist.forEach(function (item, index) {
        if (isStarted && item.deep <= deep && !item.isItem) {
          isStarted = false
        }
        if (isStarted && item.deep >= deep) {
          item.hide = hide
          item.hideNext = hide
        }
        if (ind === index) {
          isStarted = true
          deep = item.deep
          item.hideNext = !item.hideNext
          hide = item.hideNext
        }
      }, this)
    },

    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    compositeValue(item, field) {
      let fields = field.split('.')

      function gv(item, f) {
        return item[f];
      }

      let f = item;
      fields.forEach(function (v) {
        f = gv(f, v)
      })
      return f;
    },

    compositeArrayValue(item, field) {
      let fields = field.split('.')

      function gv(item, f) {
        return item[f];
      }

      let f = item;
      fields.forEach(function (v, i) {
        if (i < fields.length - 1) {
          f = gv(f, v)
        }
      })
      let ret = []
      f.forEach(function (v) {
        ret.push(v[fields[fields.length - 1]])
      })
      return ret;
    },

    isChecked(id) {
      return this.checkedItems[id]
    },

    checkAll() {
      this.kp.items.forEach(function (i) {
        this.checkedItems[i.ID] = !this.checkAllBox
      }, this)
      this.glist.forEach(function (i) {
        if (i.isItem) {
          this.checkedItems[i.it.ID] = !this.checkAllBox
        }
      }, this)
    },

    checkItem(id) {
      if (this.checkedItems[id]) {
        delete this.checkedItems[id]
        this.checkAllBox = false
      } else {
        this.checkedItems[id] = true
      }
    },

    changeFilterIndex(filter) {
      filter.value = ""
      filter.value2 = ""
      filter.operation = 0
      filter.logic = 0
      filter.multivalue = []
      filter.multivalue2 = []
      if (this.kp.settings.filterFields[filter.index].LINK) {
        filter.listRendered = false
        ApiService.get2(this.kp.settings.filterFields[filter.index].LINK).then(({data}) => {
          filter.objects = data
          Vue.set(filter, 'listRendered', true)
        }, this)
      }
    },

    filterItemText(count) {
      return `и еще ${count}`
    },

    groupFields(index, fields) {
      let ret = []
      fields.forEach(function (f, fin) {
        let hasAdd = false
        this.groups.forEach(function (gr, gin) {
          if (index !== gin) {
            if (gr.index === fin) {
              hasAdd = true
            }
          }
        }, this)
        if (!hasAdd) {
          ret.push({NAME: f.NAME, POS: fin})
        }
      }, this)
      return ret
    },

    filterString() {
      let str = ""
      if (this.applyFilters.length > 0) {
        str += `Фильтр: `
        this.applyFilters.forEach(function (filter, ind) {
          let f = this.kp.settings.filterFields[filter.index]
          if (filter.value) {
            str += ` ${f.NAME} ${f.OPERATION[filter.operation].NAME} "${filter.value}"`
            if (filter.value2) {
              str += ` и "${filter.value2}"`
            }
            if (this.applyFilters[ind + 1] && (this.applyFilters[ind + 1].value || this.applyFilters[ind + 1].multivalue.length > 0)) {
              str += ` <strong>${f.LOGIC[filter.logic].NAME}</strong> `
            }
          }
          if (filter.multivalue.length > 0) {
            str += ` ${f.NAME} ${f.OPERATION[filter.operation].NAME} `
            filter.multivalue.forEach(function (val, i) {
              if (i > 0) {
                str += ', '
              }
              str += `"${val.UF_NAME}"`
            })

            if (this.applyFilters[ind + 1] && (this.applyFilters[ind + 1].value || this.applyFilters[ind + 1].multivalue.length > 0)) {
              str += ` <strong>${f.LOGIC[filter.logic].NAME}</strong> `
            }
          }
        }, this)
      }
      return str
    },

    groupString() {
      let str = ""
      if (this.applyGroups.length > 0) {
        str += `Группировка: `
        this.applyGroups.forEach(function (group, i) {
          if (i > 0) {
            str += ", "
          }
          str += this.kp.settings.groupFields[group.index].NAME
        }, this);
      }
      return str
    },

    deleteKP() {
      if (!this.checkedItems.length) {
        this.$toasted.show(
            'Нет отмеченных запросов',
            {
              theme: "bubble",
              position: "bottom-right",
              duration: 3000,
              action: [
                {
                  text: "Закрыть",
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0);
                  }
                }
              ]
            }
        );
      } else if (confirm("Вы действительно хотите удалить выбранные записи?")) {
        let l = [];
        this.checkedItems.forEach(function (i, k) {
          l.push({ID: k})
        })
        KPService.deleteKP(l).then(({data}) => {
          if (data.error) {
            data.errorAr.forEach(function (err) {
              this.tosterErrors[this.tosterErrors.length] = this.$toasted.show(
                  err.TEXT.TEXT,
                  {
                    theme: "bubble",
                    position: "bottom-right",
                    duration: null,
                    action: [
                      {
                        text: "Закрыть",
                        onClick: (e, toastObject) => {
                          toastObject.goAway(0);
                        }
                      },
                      {
                        text: "Закрыть все",
                        onClick: () => {
                          this.tosterErrors.forEach(function (err) {
                            err.goAway(0);
                          });
                        }
                      }
                    ]
                  }
              );
            }, this);
          } else {
            console.log('govno')
            this.fetchKPs();
            this.tosterErrors[this.tosterErrors.length] = this.$toasted.show(
                'Отмеченные записи удалены',
                {
                  theme: "green",
                  position: "bottom-right",
                  duration: 5000,
                  action: [
                    {
                      text: "Закрыть",
                      onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                      }
                    }
                  ]
                }
            );
          }
        })
      }
    },

    clearFilters() {
      this.filters = []
      this.showFilterModal = false
      Vue.set(this, 'applyFilters', [])
      this.fetchKPs()
    },

    clearGroups() {
      this.groups = []
      this.showGroupModal = false
      Vue.set(this, 'applyGroups', [])
      this.fetchKPs()
    }
  }
}
</script>

<style scoped>

</style>