<template>
  <div class="auth-area__form">
    <form action="#" class="form-auth-login" @submit.prevent="loginCheck">
      <span class="reg-danger reg-danger_auth login-reg-auth" v-if="error">{{ error }}</span>
      <div class="auth-field">
        <p><label for="login-email" class="auth_label">E-mail</label></p>
        <input type="text" id="login-email" name="login-email" class="auth_input"
               v-model="credentials.login">
      </div>
      <div class="auth-field">
        <p><label for="login-pass" class="auth_label">Пароль</label></p>
        <input type="password" id="login-pass" name="login-pass" class="auth_input"
               v-model="credentials.password">
      </div>
      <!--   todo Вернуть чекбокс когда появится функционал   -->
      <!--      <div class="auth-field">-->
      <!--        <input type="checkbox" class="auth_check"><span-->
      <!--          class="auth_label label_check">Запомнить меня</span>-->
      <!--      </div>-->
      <input type="submit" class="auth_btn" value="войти в систему">
      <router-link class="link-forgot_pass" :to="{name:'ResetPassword'}">Забыли пароль?</router-link>
    </form>
  </div>
</template>


<script>
import ApiService, {UserService} from "@/common/api.service";
import {OAUTH} from "@/store/actions.type";
import {mapGetters} from "vuex";
import {SET_AVATAR, SET_NAME} from "@/store/mutations.type";

export default {
  name: "Login",
  data() {
    return {
      credentials: {
        login: "",
        password: ""
      },
      error: ""
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "currentUser"])
  },
  mounted() {

  },

  beforeCreate: function () {
    document.body.className = 'gradient';
    document.documentElement.className = 'gradient';
  },

  destroyed() {
    const body = document.getElementsByTagName('body')[0];
    const html = document.documentElement;
    body.classList.remove('gradient');
    html.classList.remove('gradient');
  },

  methods: {
    loginCheck() {
      ApiService.post("auth/getAccessToken/", this.credentials).then(
          ({data}) => {
            if (data.error === true) {
              this.error = data["text-error"];
              return;
            }
            this.$store.dispatch(OAUTH, data.access_token).then(() => {
              UserService.getUserDefaultSetting().then(({data}) => {
                this.$router.push({name: `LK/${data.START_PAGE_URL}`});
              });
              this.$store.commit(SET_NAME, this.$store.getters.currentUser.NAME);
              this.$store.commit(SET_AVATAR, this.$store.getters.currentUser.AVATAR);
            });

          }
      );
    }
  }
};
</script>
