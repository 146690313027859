<template>
  <div class="content-tab-wrapper">
    <div class="row row_link_title">
      <div class="col-md-12">
        <a @click="toggleDocs" class="link_title">
          <i class="i_arrow" :class="{i_arrow_hidden: !showDocs}"></i>
          Документы
        </a>
      </div>
    </div>
    <div class="content-tab-indent" v-if="showDocs">
      <div class="row">
        <div class="col-md-12 col-name">
          <div class="stl-pb_10">
            <span class="i-doc-desc"><i class="i-doc i-doc_y stl-vermid"></i> — документ принят</span>
            <span class="i-doc-desc"><i class="i-doc i-doc_n stl-vermid"></i> — документ отклонен </span>
            <span class="i-doc-desc"><i class="i-doc i-doc_np stl-vermid"></i> — документ не
                            проверен</span>
            <span class="i-doc-desc"><i class="i-doc i-doc_fp stl-vermid"></i> — документ формально принят</span>
          </div>

          <!-- START Основания внешнеэкономической операции -->
          <div class="content-tab-wrapper" :key="item.ID" v-for="item in docTypes()">
            <div class="row row_link_title">
              <div class="col-md-12">
                <a @click="toggleVid(item)" class="link_title">
                  <i class="i_arrow" :class="{i_arrow_hidden: item.hide}"></i>
                  {{ item.UF_NAME }}
                </a>
              </div>
            </div>
            <div class="content-tab-indent" v-if="!item.hide">
              <div class="row" :key="child.ID" v-for="child in item.CHILDS">
                <div v-if="conclusionItem.REQUEST.DOCUMENTES[child.ID]" class="col-md-12 col-name">
                  <strong>{{ child.UF_NAME }}</strong>
                </div>
                <div v-if="conclusionItem.REQUEST.DOCUMENTES[child.ID]">
                  <div class="col-md-12 col-name" :key="doc.ID"
                       v-for="doc in conclusionItem.REQUEST.DOCUMENTES[child.ID]">
                    <div class="stl-pb_10">
                      <br/>Название: {{ doc.UF_NAME }}
                    </div>
                    <div class="stl-pb_10">
                                            <span class="versia-box_doc">
                                                <a :href="docUrl(doc)"
                                                   :class="{
                                                    'link-doc-np': doc.UF_STATUS_DOC_ID === 1,
                                                    'link-doc-n': doc.UF_STATUS_DOC_ID === 2,
                                                    'link-doc-fp': doc.UF_STATUS_DOC_ID === 3,
                                                    'link-doc-y': doc.UF_STATUS_DOC_ID === 4,
                                                                }">{{ doc.UF_FILE_BASE_NAME }}</a></span>
                      <span v-if="doc.UF_IS_SIGN_ECP"
                            v-tooltip="{ content: new Object(() => ecpInfo(doc)), loadingContent: '<i>Загрузка...</i>', classes: 'message' }">
                                            <span class="e-signature">эцп</span>
                                            <span
                                                class="e-signature_data">{{ doc.UF_SIGN_ECP_DATE | dateFormat }}</span>
                                            </span>
                    </div>
                    <div class="" v-if="doc.UF_COMMENT">
                      Комментарий: {{ doc.UF_COMMENT }}<br/><br/>
                    </div>
                    <div v-if="doc.UF_DATE_ADD_NEW_VERSION">
                      Срок предоставления новой версии: {{ doc.UF_DATE_ADD_NEW_VERSION }}<br/><br/>
                    </div>
                    <div class="">
                      <div class="link_style-blue stl-w-170"
                           v-if="currentUser.ACTIVE_ROLE_CODE === 'expert_org_expert' && conclusionItem.UF_IS_EDIT"
                           @click="showStatusModal(doc)">Комментарий
                      </div>
                    </div>
                    <div class="content-tab-wrapper" v-if="doc.VERSION_LIST.length > 0">
                      <div class="row row_link_title">
                        <div class="col-md-12">
                          <a @click="toggleVid(doc)"
                             class="link_title link_suptitle">
                            <i class="i_arrow" :class="{i_arrow_hidden: !doc.hide}"></i>
                            Предыдущие версии
                          </a>
                        </div>
                      </div>
                      <div v-if="doc.hide">
                        <div class="content-tab-indent versia-tab-indent" :key="dh.ID"
                             v-for="dh in doc.VERSION_LIST">
                          <div class="row">
                            <div class="col-md-12 col-name">
                              Название: {{ dh.UF_NAME }}
                            </div>
                            <div class="col-md-12 col-name">
                                                        <span class="versia-box_doc">
                                                          <a :href="docUrl(doc)" :class="{
                                                    'link-doc-np': doc.UF_STATUS_DOC_ID === 1,
                                                    'link-doc-n': doc.UF_STATUS_DOC_ID === 2,
                                                    'link-doc-fp': doc.UF_STATUS_DOC_ID === 3,
                                                    'link-doc-y': doc.UF_STATUS_DOC_ID === 4,
                                                                }">{{ dh.UF_FILE_BASE_NAME }}</a>
                                                          <span class="link-doc_required">{{ dh.UF_COMMENT }}</span>
                                                        </span>
                              <span v-if="doc.UF_IS_SIGN_ECP"
                                    v-tooltip="{ content: new Object(() => ecpInfo(dh)), loadingContent: '<i>Загрузка...</i>', classes: 'message' }">
                                                            <span v-if="dh.UF_IS_SIGN_ECP"
                                                                  class="e-signature">эцп</span>
                                                            <span v-if="dh.UF_IS_SIGN_ECP"
                                                                  class="e-signature_data">{{
                                                                dh.UF_SIGN_ECP_DATE | dateFormat
                                                              }}</span>
                                                            </span>
                            </div>
                            <div class="" v-if="dh.UF_COMMENT">
                              Комментарий: {{ dh.UF_COMMENT }}<br/><br/>
                            </div>
                            <div v-if="dh.UF_DATE_ADD_NEW_VERSION">
                              Срок предоставления новой версии: {{ dh.UF_DATE_ADD_NEW_VERSION}}<br/><br/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- content-tab-indent versia-tab-indent -->
                      <!-- content-tab-wrapper -->
                      <div>&nbsp;</div>
                    </div>
                  </div>
                </div>
                <!-- col-name -->
              </div>
              <div v-if="conclusionItem.REQUEST.DOCUMENTES[item.ID]">
                <div class="col-md-12 col-name" :key="doc.ID"
                     v-for="doc in conclusionItem.REQUEST.DOCUMENTES[item.ID]">
                  <div class="stl-pb_10">
                    <br/>Название: {{ doc.UF_NAME }}
                  </div>
                  <div class="stl-pb_10">
                                            <span class="versia-box_doc"
                                            >
                                                <a :href="docUrl(doc)"
                                                   :class="{
                                                    'link-doc-np': doc.UF_STATUS_DOC_ID === 1,
                                                    'link-doc-n': doc.UF_STATUS_DOC_ID === 2,
                                                    'link-doc-fp': doc.UF_STATUS_DOC_ID === 3,
                                                    'link-doc-y': doc.UF_STATUS_DOC_ID === 4,
                                                                }">{{ doc.UF_FILE_BASE_NAME }}</a></span>
                    <span
                        v-tooltip="{ content: new Object(() => ecpInfo(doc)), loadingContent: '<i>Загрузка...</i>', classes: 'message' }">
                    <span v-if="doc.UF_IS_SIGN_ECP" class="e-signature">эцп</span>
                    <span v-if="doc.UF_IS_SIGN_ECP"
                          class="e-signature_data">{{ doc.UF_SIGN_ECP_DATE | dateFormat }}</span></span>
                  </div>
                  <div class="col-name" v-if="doc.UF_COMMENT">
                    Комментарий: {{ doc.UF_COMMENT }}<br/><br/>
                  </div>
                  <div class="col-name" v-if="doc.UF_DATE_ADD_NEW_VERSION">
                    Срок предоставления новой версии: {{ doc.UF_DATE_ADD_NEW_VERSION }}<br/><br/>
                  </div>
                  <div class="">
                    <div class="link_style-blue stl-w-170"
                         v-if="currentUser.ACTIVE_ROLE_CODE === 'expert_org_expert' && conclusionItem.UF_IS_EDIT"
                         @click="showStatusModal(doc)">Комментарий
                    </div>
                  </div>
                  <div class="content-tab-wrapper" v-if="doc.VERSION_LIST.length > 0">
                    <div class="row row_link_title">
                      <div class="col-md-12">
                        <a @click="toggleVid(doc)"
                           class="link_title link_suptitle">
                          <i class="i_arrow" :class="{i_arrow_hidden: !doc.hide}"></i>
                          Предыдущие версии
                        </a>
                      </div>
                    </div>
                    <div v-if="doc.hide">
                      <div class="content-tab-indent versia-tab-indent" :key="dh.ID"
                           v-for="dh in doc.VERSION_LIST">
                        <div class="row">
                          <div class="col-md-12 col-name">
                            Название: {{ doc.UF_NAME }}
                          </div>
                          <div class="col-md-12 col-name">
                                                        <span class="versia-box_doc">
                                                          <a href="#" :class="{
                                                    'link-doc-np': doc.UF_STATUS_DOC_ID === 1,
                                                    'link-doc-n': doc.UF_STATUS_DOC_ID === 2,
                                                    'link-doc-fp': doc.UF_STATUS_DOC_ID === 3,
                                                    'link-doc-y': doc.UF_STATUS_DOC_ID === 4,
                                                                }">{{ dh.UF_NAME }}</a>
                                                          <span class="link-doc_required">{{ dh.UF_COMMENT }}</span>
                                                        </span>
                            <span v-if="dh.UF_IS_SIGN_ECP"
                                  class="e-signature">эцп</span>
                            <span v-if="dh.UF_IS_SIGN_ECP"
                                  class="e-signature_data">{{ dh.UF_SIGN_ECP_DATE | dateFormat }}</span>
                          </div>
                          <div class="" v-if="dh.UF_COMMENT">
                            Комментарий: {{ dh.UF_COMMENT }}<br/><br/>
                          </div>
                          <div v-if="dh.UF_DATE_ADD_NEW_VERSION">
                            Срок предоставления новой версии: {{ dh.UF_DATE_ADD_NEW_VERSION }}<br/><br/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- content-tab-indent versia-tab-indent -->
                    <!-- content-tab-wrapper -->
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
              <!-- row -->
            </div>
            <!-- content-tab-indent -->

          </div>
          <!-- content-tab-wrapper -->

        </div>
        <!-- col-md-12 col-name -->
      </div>
      <!-- row -->
      <div class="modal fade in show" tabindex="-1" v-if="statusCard.ID > 0" role="dialog"
           aria-labelledby="myModalLabel">
        <div class="modal-dialog " style="width: 490px" role="document">
          <div class="modal-content">
            <div class="modal-add-document"
                 style="z-index: 10000;width: 490px">
              <div class="modal-header main-clear">
                <span class="modal-title">Изменить статус</span>
                <a @click="statusCard.ID=0" class="modal-exit stl-FR"></a>
              </div>
              <div class="modal-body">
                <div class="modal_row main-clear">
                  <span class="modal-field_name">Статус</span>
                  <span>
                                         <select style="width: 465px"
                                                 v-model="statusCard.UF_STATUS_DOC_ID"
                                                 class="main_input main_select">
                                           <option></option>
                                            <option v-for="item in directory.status_documents_request" :key="item.ID"
                                                    v-show="item.UF_CODE==='document_accepted' || item.UF_CODE==='document_rejected' "
                                                    :value="item.ID">{{ item.UF_NAME }}</option>
                                        </select>
                                    </span>
                </div>
                <div class="modal_row main-clear"
                     v-if="statusCode(statusCard.UF_STATUS_DOC_ID) === 'document_rejected'">
                  <span class="modal-field_name">Комментарий</span>
                  <span>
                                         <textarea style="width: 465px;height: 100px" v-model="statusCard.UF_COMMENT"
                                                   class="main_input "/>
                                    </span>
                </div>
                <div class="modal_row main-clear"
                     v-if="statusCode(statusCard.UF_STATUS_DOC_ID) === 'document_rejected'">
                  <span class="modal-field_name" style="max-width: none">Срок предоставления новой версии документа</span>
                  <datepicker
                      v-model="statusCard.UF_DATE_ADD_NEW_VERSION"
                      :language="ru"
                      :format="dateFormat"
                      input-class="main_input small_calendar small_calendar_mid main-inlineBlock"
                  ></datepicker>
                </div>
                <div class="modal_row main-clear">
                  <button class="link_style-blue modal_btn_wid" style="width: 30%"
                          @click="changeDocStatus"
                          v-if="statusCard.UF_STATUS_DOC_ID > 0">
                    сохранить
                  </button>
                  <div class="link_style-white stl-FR modal_btn_wid" style="width: 30%"
                       @click="statusCard.ID=0">
                    отмена
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content-tab-indent -->
  </div>
  <!-- content-tab-wrapper -->
</template>

<script>
import {mapGetters} from "vuex";
import Vue from "vue";
import {RequestService} from "@/common/api.service";
import "@/scripts/cadesplugin_api";
import moment from "moment";
import {API_URL} from "@/common/config";
import {ru} from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import {Funcs} from "@/common/funcs";

export default {
  name: "CardDocs",
  props: {
    page: {
      type: Number
    }
  },
  data() {
    return {
      ecpCert: "",
      ecpCerts: [],
      ecpValue: "",
      ecpPluginEnabled: false,
      showDocs: true,
      isSignStarted: false,
      ru: ru,
      statusCard: {
        ID: 0,
        UF_STATUS_DOC_ID: 0,
        UF_DATE_ADD_NEW_VERSION: ""
      }
    };
  },
  components: {Datepicker},
  async mounted() {
    await this.$store.dispatch('fetchDirectories', [
      "vid_documents_request",
      "status_documents_request"
    ]);
    this.directory.vid_documents_request.forEach(function (item) {
      Vue.set(item, 'showUploadModal', false)
    })
  },
  computed: {
    ...mapGetters([
      "conclusionItem",
      "isConclusionsLoading",
      "directory",
      "currentUser"
    ]),
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    docTypes() {
      let ret = []
      for (let i = 0; i < this.directory.vid_documents_request.length; i++) {
        if (this.page === 1 && this.directory.vid_documents_request[i].UF_CODE !== 'technical_documentation') {
          ret.push(this.directory.vid_documents_request[i])
        }
        if (this.page === 2 && this.directory.vid_documents_request[i].UF_CODE === 'technical_documentation') {
          ret.push(this.directory.vid_documents_request[i])
        }
      }
      return ret
    },
    showStatusModal(doc) {
      this.statusCard.ID = doc.ID
      this.statusCard.UF_VID_DOC_REQUEST = doc.UF_VID_DOC_REQUEST
      this.statusCard.UF_STATUS_DOC_ID = doc.UF_STATUS_DOC_ID
      this.statusCard.UF_COMMENT = doc.UF_COMMENT
      this.statusCard.UF_DATE_ADD_NEW_VERSION = doc.UF_DATE_ADD_NEW_VERSION
    },
    statusCode(id) {
      if (id > 0) {
        return Funcs.getDirectoryValue(this.directory.status_documents_request, id).UF_CODE
      } else {
        return ""
      }
    },
    changeDocStatus() {
      RequestService.changeStatusDocument(this.conclusionItem.REQUEST.ID, this.statusCard.ID, this.statusCard).then(({data}) => {
        if (!data.error) {
          this.statusCard.ID = 0
          RequestService.item(this.requestItem.ID).then(({data}) => {
            this.conclusionItem.REQUEST.DOCUMENTES = data.REQUEST.DOCUMENTES;
          })
        }
      }, this)
    },
    toggleDocs() {
      this.showDocs = !this.showDocs
    },
    toggleVid(item) {
      Vue.set(item, 'hide', !item.hide)
    },
    docUrl(object) {
      return API_URL.replace(new RegExp("[\\/]+$"), "") + object.UF_FILE;
    },
    async ecpInfo(doc) {
      return await RequestService.getECPInfoFile(doc.ID).then(({data}) => {
        return `<div class="modal-content">
<div class="text-center"><span class="modal-title">Документ подписан <br> электронной
подписью</span></div>
<div class="modal-body">
<div class="modal_row modal_flex">
<span class="modal-field_name">Сертификат</span>
<span>${data.result.SERTIFICATE_NUMBER}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Организация</span>
<span>${data.result.COMPANY}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Владелец</span>
<span>${data.result.OWNER_FIO}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Должность</span>
<span>${data.result.POSITION}</span>
</div>
<div class="modal_row modal_flex">
<span class="modal-field_name">Действителен</span>
<span>${data.result.DATE_USE_SERTIFICATE}</span>
</div>
</div>
</div>`
      }).catch(() => 'ERROR')
    }
  },
  filters: {
    dateFormat(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
}
</script>

<style scoped>

</style>